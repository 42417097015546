import axiosGrmsClient from "./clients/axiosGrmsClient";
const resource = "/grievance";

export default {
  getGrievanceStatus(params = "") {
    let str = `${resource}/stats`;
    if (params) {
      str = str + `?${params}`;
    }
    return axiosGrmsClient.get(str);
  },
  postGrievance(data) {
    return axiosGrmsClient.post(`${resource}/save`, data);
  },
  postGrievanceStatusEmail(data) {
    return axiosGrmsClient.post(`${resource}/grievanceStatusEmail`, data);
  },
};
